import { ColumnHeaderProps } from "@/constants/columnHeader";
import { StandardLayoutContextProvider } from "@/features/standardLayout/Context";
import { ReactNode } from "react";

interface BestPracticeQueryClientProviderProps {
    children: ReactNode;
    queryKeyFactory: any;
    searchFieldsHeader: ColumnHeaderProps[];
}

export const StandardLayoutProvider = ({ children, queryKeyFactory, searchFieldsHeader }: BestPracticeQueryClientProviderProps) => {
    return (
        <StandardLayoutContextProvider queryKeyFactory={queryKeyFactory} searchFieldsHeader={searchFieldsHeader}>
            {children}
        </StandardLayoutContextProvider>
    )
}