/**
 * 이 파일은 특정 업체의 기능을 활성화/비활성화하는 데 사용됩니다.
 * 
 * 설명:
 * 이 파일에는 다른 업체에 대한 특정 기능의 가용성을 제어하는 데 사용할 수 있는 변수가 포함되어 있습니다.
 * 이러한 변수의 값을 수정함으로써 특정 업체에 대한 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 다른 업체의 요구 사항에 따라 응용 프로그램의 동작을 사용자 정의할 수 있는 유연성을 제공합니다.
 * 
 * 사용법:
 * 이 파일을 사용하려면 여기에서 정의된 변수를 코드베이스의 다른 부분으로 가져올 수 있습니다.
 * 이러한 변수를 참조함으로써 업체에 따라 조건부로 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 단일 코드베이스를 다른 클라이언트나 조직에 맞게 사용자 정의할 수 있습니다.
 * 
 * 참고:
 * 이러한 변수의 값이 요구 사항에 맞게 제대로 관리되고 업데이트되도록 주의해야 합니다.
 * 코드에 하드코딩된 값을 피하고 대신 이러한 변수에 의존하여 구성 가능성을 확보하는 것이 중요합니다.
 */

export const customFunctions = {
    // ADD_SETTING_EQUIPMENT_AUTOCOMPLETE: 설비 선택하는 AutoComplete 컴포넌트 추가 여부
    ADD_SETTING_EQUIPMENT_AUTOCOMPLETE: process.env.REACT_APP_ADD_SETTING_EQUIPMENT_AUTOCOMPLETE === 'true' || false,

    // ADD_SETTING_ITEM_AUTOCOMPLETE: 품목 선택하는 AutoComplete 컴포넌트 추가 여부
    ADD_SETTING_ITEM_AUTOCOMPLETE: process.env.REACT_APP_ADD_SETTING_ITEM_AUTOCOMPLETE === 'true' || false,

    // ADD_INPUT_ERP_WORK_ORDER: 생산관리 - 연동) 생산계획 가져오기 기능 추가 여부
    ADD_INPUT_ERP_WORK_ORDER: process.env.REACT_APP_ADD_INPUT_ERP_WORK_ORDER === 'true' || false,

    // ADD_ERP_WORK_RECORD: 생산관리 - 연동) 작업 실적 전달하기 기능 추가 여부
    ADD_ERP_WORK_RECORD: process.env.REACT_APP_ADD_ERP_WORK_RECORD === 'true' || false,

    // 외주작업지시 페이지를 보여줄지에 대한 여부
    ADD_SHOW_OPERATION_OUTSOURCING_WORK: process.env.REACT_APP_ADD_SHOW_OPERATION_OUTSOURCING_WORK === 'true' || false,

    // ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER: 생산관리 - 연동) 외주 생산계획 가져오기 기능 추가 여부
    ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER: process.env.REACT_APP_ADD_INPUT_ERP_OUTSOURCING_WORK_ORDER === 'true' || false,

    // ADD_ERP_WORK_RECORD_OUTSOURCING: 생산관리 - 연동) 외주 작업 실적 전달하기 기능 추가 여부
    ADD_ERP_WORK_RECORD_OUTSOURCING: process.env.REACT_APP_ADD_ERP_WORK_RECORD_OUTSOURCING === 'true' || false,

    // ADD_SETTING_WORK_DATE_SELECT: 생산관리 - 작업날짜 선택 기능 추가 여부
    ADD_SETTING_WORK_DATE_SELECT: process.env.REACT_APP_ADD_SETTING_WORK_DATE_SELECT === 'true' || false,

    // ADD_WORK_ID_COLUMN: 생산관리 - 작업 번호 열 추가 여부
    ADD_WORK_ID_COLUMN: process.env.REACT_APP_ADD_WORK_ID_COLUMN === 'true' || false,
    // ADD_EXTERNAL_OUTSOURCING_COLUMN: 생산관리 - 사외 외주 여부 컬럼 추가 여부
    ADD_EXTERNAL_OUTSOURCING_COLUMN: process.env.REACT_APP_ADD_EXTERNAL_OUTSOURCING_COLUMN === 'true' || false,
    // ADD_DESCRIPTION_COLUMN: 생산관리 - 비고 컬럼 추가 여부
    ADD_DESCRIPTION_COLUMN: process.env.REACT_APP_ADD_DESCRIPTION_COLUMN === 'true' || false,

    // ADD_SYSTEM_MANAGEMENT_MENU_SETTING: 시스템 관리 메뉴 설정 추가 여부
    ADD_SYSTEM_MANAGEMENT_MENU_SETTING: process.env.REACT_APP_ADD_SYSTEM_MANAGEMENT_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING: 기준정보 - 공통코드 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING: 기준정보 - 권한 그룹 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING: 기준정보 - 부서 코드 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING: 기준정보 - ALC MODEL 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_PARTNER_MENU_SETTING: 기준정보 - 거래처 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_PARTNER_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_PARTNER_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_BOM_MENU_SETTING: 기준정보 - BOM 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_BOM_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_BOM_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING: 기준정보 - BOM 목록 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING === 'true' || false,

    // ADD_STANDARD_INFO_FILE_MENU_SETTING: 기준정보 - 파일 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_FILE_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_FILE_MENU_SETTING === 'true' || false,

    // ADD_PERIPHERAL_MENU_SETTING: 기준정보 - 주변장치 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING === 'true' || false,

    // ADD_MACHINE_MENU_SETTING: 기준정보 - 기계 메뉴 설정 추가 여부
    ADD_STANDARD_INFO_MACHINE_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_MACHINE_MENU_SETTING === 'true' || false,

    // ADD_DOWNTIME_REASON_LOG_MENU_SETTING: 생산관리 - 정지유형별 통계 메뉴 설정 추가 여부
    ADD_DOWNTIME_REASON_LOG_MENU_SETTING: process.env.REACT_APP_ADD_DOWNTIME_REASON_LOG_MENU_SETTING === 'true' || false,

    // ADD_RECEIPT_HISTORY_MENU_SETTING: 재고관리 - 재고수불이력 메뉴 설정 추가 여부
    ADD_RECEIPT_HISTORY_MENU_SETTING: process.env.REACT_APP_ADD_RECEIPT_HISTORY_MENU_SETTING === 'true' || false,

    // ADD_BARCODE_LIFT_MENU_SETTING: 재고관리 - 재고바코드해제 메뉴 설정 추가 여부
    ADD_BARCODE_LIFT_MENU_SETTING: process.env.REACT_APP_ADD_BARCODE_LIFT_MENU_SETTING === 'true' || false,

    // ADD_BARCODE_MERGE_MENU_SETTING: 재고관리 - 재고바코드병합 메뉴 설정 추가 여부
    ADD_BARCODE_MERGE_MENU_SETTING: process.env.REACT_APP_ADD_BARCODE_MERGE_MENU_SETTING === 'true' || false,

    // ADD_LACK_SAFETY_MENU_SETTING: 재고관리 - 안전재고부족조회 메뉴 설정 추가 여부
    ADD_LACK_SAFETY_MENU_SETTING: process.env.REACT_APP_ADD_LACK_SAFETY_MENU_SETTING === 'true' || false,

    // ADD_INVENTORY_LIFE_MENU_SETTING: 재고관리 - 재고수명조회 메뉴 설정 추가 여부
    ADD_INVENTORY_LIFE_MENU_SETTING: process.env.REACT_APP_ADD_INVENTORY_LIFE_MENU_SETTING === 'true' || false,

    // ADD_STORAGE_MANAGEMENT_MENU_SETTING: 입출고관리 메뉴 설정 추가 여부
    ADD_STORAGE_MANAGEMENT_MENU_SETTING: process.env.REACT_APP_ADD_STORAGE_MANAGEMENT_MENU_SETTING === 'true' || false,

    // ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING: 영업관리 - 수주항목 메뉴 설정 추가 여부
    ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING: process.env.REACT_APP_ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING === 'true' || false,
    // ADD_ORDER_MENU_SETTING: 영업관리 - 발주현황 메뉴 설정 추가 여부
    // ADD_ORDER_MENU_SETTING: process.env.REACT_APP_ADD_ORDER_MENU_SETTING === 'true' || false,
    // ADD_ORDER_ITEMS_MENU_SETTING: 영업관리 - 발주항목 메뉴 설정 추가 여부
    // ADD_ORDER_ITEMS_MENU_SETTING: process.env.REACT_APP_ADD_ORDER_ITEMS_MENU_SETTING === 'true' || false,
    // ADD_STOCK_PLAN_MENU_SETTING: 영업관리 - 재고계획 메뉴 설정 추가 여부
    // ADD_STOCK_PLAN_MENU_SETTING: process.env.REACT_APP_ADD_STOCK_PLAN_MENU_SETTING === 'true' || false,
    // ADD_TRANSPORTATION_MENU_SETTING: 영업관리 - 배차 메뉴 설정 추가 여부
    // ADD_TRANSPORTATION_MENU_SETTING: process.env.REACT_APP_ADD_TRANSPORTATION_MENU_SETTING === 'true' || false,

    // ADD_QUALITY_MENU_SETTING: 품질관리 메뉴 설정 추가 여부
    ADD_QUALITY_MENU_SETTING: process.env.REACT_APP_ADD_QUALITY_MENU_SETTING === 'true' || false,
    // ADD_DEFECT_STATISTICS_MENU_SETTING: 불량통계(자주검사) 메뉴 설정 추가 여부
    ADD_DEFECT_STATISTICS_MENU_SETTING: process.env.REACT_APP_ADD_DEFECT_STATISTICS_MENU_SETTING === 'true' || false,
    // ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING: 작업표준서 관리 메뉴 설정 추가 여부
    ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING: process.env.REACT_APP_ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING === 'true' || false,
    // ADD_CHANGE_LIST_MENU_SETTING: 변경점 정보 관리 메뉴 설정 추가 여부
    ADD_CHANGE_LIST_MENU_SETTING: process.env.REACT_APP_ADD_CHANGE_LIST_MENU_SETTING === 'true' || false,
    // ADD_PRE_WAREHOUSE_LIST_MENU_SETTING: 수입검사 메뉴 설정 추가 여부
    ADD_PRE_WAREHOUSE_LIST_MENU_SETTING: process.env.REACT_APP_ADD_PRE_WAREHOUSE_LIST_MENU_SETTING === 'true' || false,

    // ADD_KPI_MENU_SETTING: KPI 메뉴 설정 추가 여부
    ADD_KPI_MENU_SETTING: process.env.REACT_APP_ADD_KPI_MENU_SETTING === 'true' || false,

    // ADD_MONITORING_MENU_SETTING: 모니터링 메뉴 설정 추가 여부
    ADD_MONITORING_MENU_SETTING: process.env.REACT_APP_ADD_MONITORING_MENU_SETTING === 'true' || false,

    // ADD_EQUIPMENT_MOLD_MENU_SETTING: 설비금형기준 메뉴 설정 추가 여부
    ADD_EQUIPMENT_MOLD_MENU_SETTING: process.env.REACT_APP_ADD_EQUIPMENT_MOLD_MENU_SETTING === 'true' || false,

    // ADD_EQUIPMENT_MONITORING_MENU_SETTING: 설비 모니터링 메뉴 설정 추가 여부
    ADD_EQUIPMENT_MONITORING_MENU_SETTING: process.env.REACT_APP_ADD_EQUIPMENT_MONITORING_MENU_SETTING === 'true' || false,

    // ADD_PMS_MENU_SETTING: PMS 메뉴 설정 추가 여부
    ADD_PMS_MENU_SETTING: process.env.REACT_APP_ADD_PMS_MENU_SETTING === 'true' || false,
    // ADD_PMS_PRESS_MONITORING: 현황 모니터링 - PMS 분석모니터링 내용 추가 여부
    ADD_PMS_PRESS_MONITORING: process.env.REACT_APP_ADD_PMS_PRESS_MONITORING === 'true' || false,

    // ADD_SCM_MENU_SETTING: SCM 메뉴 설정 추가 여부
    ADD_SCM_MENU_SETTING: process.env.REACT_APP_ADD_SCM_MENU_SETTING === 'true' || false,

    // 안료 투입 여부 
    ADD_PIGMENT_INPUT: process.env.REACT_APP_ADD_PIGMENT_INPUT === 'true' || false,

    // ADD_ADD_EXTERNAL_OUTSOURCING_WORK_ROW: 생산관리 - 사외 외주 작업 추가 행 추가 여부
    ADD_ADD_EXTERNAL_OUTSOURCING_WORK_ROW: process.env.REACT_APP_ADD_ADD_EXTERNAL_OUTSOURCING_WORK_ROW === 'true' || false,

    // ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING: 기준정보 - 아이템별 BOM 조회 및 수정 
    ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING: process.env.REACT_APP_ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING === 'true' || false,

    // ADD_PERFORMANCE_MENU_SETTING: 생산관리 - 생산실적조회 메뉴 설정 추가 여부
    ADD_PERFORMANCE_MENU_SETTING: process.env.REACT_APP_ADD_PERFORMANCE_MENU_SETTING === 'true' || false,

    // ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING: 생산관리 - 외주 실적조회 메뉴 설정 추가 여부
    ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING: process.env.REACT_APP_ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING === 'true' || false,

    // 불량 로케이션 추가
    ADD_DEFECT_LOCATION: process.env.REACT_APP_ADD_DEFECT_LOCATION === 'true' || false,
    // 재고변환 타입 추가 
    ADD_TRANSFORM_TYPE: process.env.REACT_APP_ADD_TRANSFORM_TYPE === 'true' || false,

    // 사용자의 부서 정보 추가
    ADD_USER_DEPARTMENT_INFORM: process.env.REACT_APP_ADD_USER_DEPARTMENT_INFORM === 'true' || false,

    // 사용자 접속 현황 페이지 추가 
    ADD_USER_ACCESS_STATUS: process.env.REACT_APP_ADD_USER_ACCESS_STATUS === 'true' || false,

    // 생산관리 - 작업일지 - ERP 작업번호 열 추가 여부
    ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN: process.env.REACT_APP_ADD_WORK_LOG_ERP_WORK_ORDER_COLUMN === 'true' || false,

    // 대시보드에 통계성 데이터 추가
    ADD_DASHBOARD_EQUIPMENT_ANALYSIS: process.env.REACT_APP_ADD_DASHBOARD_EQUIPMENT_ANALYSIS === 'true' || false,

    // 설비별 통계성 데이터 카운터 라벨 제거 로직 추가 
    ADD_REMOVE_EQUIPMENT_ANALYSIS_COUNTER_LABEL: process.env.REACT_APP_ADD_REMOVE_EQUIPMENT_ANALYSIS_COUNTER_LABEL === 'true' || false,

    // [태광산업] 한 제품에 대한 여러 공정이 연결 되어있으면, 공정별 생산계획을 생성할 수 있도록 추가
    ADD_PROCESS_BASED_PRODUCTION_PLAN: process.env.REACT_APP_ADD_PROCESS_BASED_PRODUCTION_PLAN === 'true' || false,

    // [경남케미컬] 재고입고시 LOT 필수값 처리 추가
    ADD_VALIDATION_INCOMING_LOT: process.env.REACT_APP_ADD_VALIDATION_INCOMING_LOT === 'true' || false,

    // [비티텔레콤] KPI 메뉴 설정 추가 여부
    ADD_BTTELECOM_KPI_MENU_SETTING: process.env.REACT_APP_ADD_BTTELECOM_KPI_MENU_SETTING === 'true' || false,

    // [태강스틸] GPS 시스템 메뉴 설정 추가 여부
    ADD_GPS_SYSTEM_MENU_SETTING: process.env.REACT_APP_ADD_GPS_SYSTEM_MENU_SETTING === 'true' || false,

    // [태강스틸] 벌크입고 버튼 추가 여부
    ADD_BULK_INCOMING_BUTTON: process.env.REACT_APP_ADD_BULK_INCOMING_BUTTON === 'true' || false,
    // ...다른 기능에 대한 추가 변수를 여기에 추가할 수 있습니다.

    // [경남] 생산계획 - 생산계획 생성시 작업지시서 포장단위 생성여부
    ADD_SETTING_PACKAGING_MACHINE: process.env.REACT_APP_ADD_SETTING_PACKAGING_MACHINE === 'true' || false,

    ADD_DATA_DISPLAY_MENU_SETTING: process.env.REACT_APP_ADD_DATA_DISPLAY_MENU_SETTING === 'true' || false,

    // 테스트 페이지 메뉴에 추가 여부
    ADD_BEST_PRACTICE_TEST_MENU_SETTING: process.env.REACT_APP_ADD_BEST_PRACTICE_TEST_MENU_SETTING === 'true' || false,
}